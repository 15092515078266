import React, { useContext, useEffect, useState, useRef } from "react";
import api from "../../services/api";
import Navbar from "../../components/Navbar/Navbar";
import {
  useToast,
  Box,
  Flex,
  Button,
  FormControl,
  Input,
  Stack,
  Text,
  Spacer,
  Select,
  Container,
  Icon,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
} from "@chakra-ui/react";
import { UserContext } from "../../contexts/User";
import { PlanContext } from "../../contexts/Plan";
import JoditEditor from "../../components/Editor";
import { OrderContext } from "../../contexts/Order";
import { IoAddCircleOutline, IoTrashOutline } from "react-icons/io5";
import { AiOutlineUpload } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";

export default function CreateOrder() {
  const toast = useToast();
  const { listClientsApi } = useContext(UserContext);
  const { createOrderApi } = useContext(OrderContext);

  const [showModal, setShowModal] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [clients, setClients] = useState([]);
  const [inputs, setInputs] = useState([]);
  const fileInputRefs = useRef([]);
  const [title, setTitle] = useState("");
  const [client, setClient] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [planID, setPlanID] = useState("");
  const [fastDelivery, setFastDelivery] = useState(0);
  const [initialLanguage, setInitialLanguage] = useState("");
  const [finalLanguage, setFinalLanguage] = useState("");
  const [fastDeliveryDays, setFastDeliveryDays] = useState("");
  const [magazineName, setMagazineName] = useState("");
  const [magazineEdit, setMagazineEdit] = useState("");
  const [wordsNumberOriginal, setWordsNumberOriginal] = useState("");
  const [wordsNumberActual, setWordsNumberActual] = useState("");
  const [totalReference, setTotalReference] = useState("");
  const [figures, setFigures] = useState("");
  const [areaID, setAreaID] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [description, setDescription] = useState("");
  const [translated, setTranslated] = useState();
  const [services, setServices] = useState([]);
  const [aditionalServices, setAditionalServices] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [fastDeliveryDaysMagazine, setFastDeliveryDaysMagazine] = useState("");
  const [manualSelect, setManualSelect] = useState(true);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const { type } = useParams();

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  const handleInputTitle = (e) => setTitle(e.target.value);
  const handleInputClientID = (e) => setClient(e.target.value);

  const handleInputTypeDocument = (e) => setTypeDocument(e.target.value);
  const handleInputPlanID = (e) => {
    setPlanID(e.target.value);
    if (e.target.value == 3) {
      setMagazineEdit(1);
    }
  };
  const handleInputInitialLanguage = (e) => setInitialLanguage(e.target.value);
  const handleInputFinalLanguage = (e) => setFinalLanguage(e.target.value);
  const handleInputFastDelivery = (e) => setFastDelivery(e.target.value);
  const handleInputFastDeliveryDays = (e) =>
    setFastDeliveryDays(e.target.value);
  const handleInputFastDeliveryDaysMagazine = (e) =>
    setFastDeliveryDaysMagazine(e.target.value);
  const handleInputMagazineName = (e) => setMagazineName(e.target.value);
  const handleInputMagazineEdit = (e) => setMagazineEdit(e.target.value);
  const handleInputWordsOriginal = (e) =>
    setWordsNumberOriginal(e.target.value);
  const handleInputWordsActual = (e) => setWordsNumberActual(e.target.value);
  const handleInputFigures = (e) => setFigures(e.target.value);
  const handleInputTotalReference = (e) => setTotalReference(e.target.value);
  const handleInputAreaID = (e) => setAreaID(e.target.value);
  const handleTranslated = (e) => setTranslated(e.target.value);
  const handleManualSelect = (e) => {
    setManualSelect(e.target.value);
  };
  const handleInputKeywords = (e) => {
    const array = e.target.value.replace(";", "").split(" ");
    setKeywords(array);
  };

  const handleInputDescription = (e) => {
    setDescription(e);
  };

  const handleBlurAreaChange = (content) => {
    setTextAreaValue(content);
  };

  const adicionarInput = () => {
    setInputs([...inputs, { type: "" }]);
  };

  const handleChange = (index) => (event) => {
    const newInputs = [...inputs];
    newInputs[index].file = event.target.files[0];
    setInputs(newInputs);
  };

  const excluirInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  async function listClients() {
    try {
      const clients = await listClientsApi();
      setClients(clients.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function createOrder() {
    try {
      const requestData = {
        title: title,
        client_id: client,
        abstract: textAreaValue,
        type_document: typeDocument,
        plan_id: type === "geral" ? planID : 4,
        fast_delivery: fastDelivery,
        language_origin: initialLanguage,
        language_final: finalLanguage,
        fast_delivery: fastDelivery,
        fast_delivery_days: fastDeliveryDays,
        fast_delivery_magazine: fastDelivery == 1 ? 1 : 0,
        fast_delivery_days_magazine: type === 'geral' ? fastDeliveryDaysMagazine : fastDeliveryDays,
        magazine_name: magazineName,
        magazine_edit: type === 'geral' ? magazineEdit : 1,
        words_number_original: wordsNumberOriginal,
        total_reference: totalReference,
        qty_figure: figures,
        services: services,
        aditional_services: aditionalServices,
        area_id: areaID,
        keywords: keywords,
        discount: discount,
        description: description,
        translated: type === "geral" ? translated : 0,
        manual_select: manualSelect,
        type_service: type === "geral" ? "general" : "edit-norms",
      };
      
      if (magazineEdit == 1){
        console.log("ediçao", magazineEdit);
        if (totalReference === "" || totalReference == 0) {
          toast({
            title: "Por favor, preencha o campo 'Número de referências'.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }
      
      setShowModal(true);
      const responseData = await createOrderApi(requestData);

      // Extrair o ID da order da resposta da API
      const orderId = responseData.data?.[0].id;
      const userId = responseData.data?.[0].user_id;

      setFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      formData.append("order_id", orderId);
      formData.append("user_id", userId);
      const token = localStorage.getItem("token");

      try {
        await api.post("upload/client/file", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        setFiles([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        console.error("Erro no upload dos arquivos:", error);
      }

      if (responseData.status === 200) {
        // Faça algo com a resposta do servidor
        toast({
          title: "Serviço Criado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }

      if (responseData.status === 202) {
        // Faça algo com a resposta do servidor
        toast({
          title:
            "Serviço Criado, mas precisará selecionar o expert manualmente",
          status: "warning",
          duration: 7000,
          isClosable: true,
        });
      }
      setShowModal(false);
      console.log(responseData);
      // Redirecione para a página de serviços
      window.location.pathname = "/servicos";
    } catch (error) {
      setShowModal(false);
      toast({
        title:
          "Não foi possível criar o serviço, verifique os campos e tente novamente",
        status: "error",
        duration: 7000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    listClients();
  }, []);

  useEffect(() => {
    fileInputRefs.current = fileInputRefs.current.slice(0, inputs.length);
  }, [inputs]);

  return (
    <>
      <Navbar />

      <Container position="absolute" top={120} ml={300} minWidth={"75vw"}>
        <Box
          bg="#ffff"
          borderRadius="15px"
          boxShadow="0px 7px 16px 4px #EAEAEA"
        >
          <Flex display="flex" padding={5} alignItems="center">
            <Box>
              <Text fontSize="24px" fontWeight="600">
                Adicionar um novo Serviço{" "}
                {type == "geral" ? "Geral" : "de Edição de Normas"}
              </Text>
            </Box>

            <Spacer />
          </Flex>
        </Box>

        <Flex
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop="45px"
        >
          <Box
            marginBottom={5}
            boxShadow="xl"
            padding={35}
            borderRadius={15}
            backgroundColor="white"
          >
            <Box>
              <Stack marginTop="15px" marginBottom="15px">
                <Text fontSize="lg">Detalhes</Text>
              </Stack>
              <FormControl display="inline-flex">
                {clients && (
                  <Select
                    onChange={handleInputClientID}
                    placeholder="Selecione um cliente"
                  >
                    {clients.map((index) => (
                      <option key={index.userID} value={index.userID}>
                        {index.name}
                      </option>
                    ))}
                  </Select>
                )}

                {type === "geral" && (
                  <>
                    <Select
                      onChange={handleInputPlanID}
                      placeholder="Selecione um plano"
                      marginLeft="1%"
                    >
                      <option value={1}>Basic</option>
                      <option value={2}>Pro</option>
                      <option value={3}>Full</option>
                    </Select>

                    <Select
                      onChange={handleInputTypeDocument}
                      placeholder="Selecione tipo de serviço"
                      marginLeft="1%"
                    >
                      <option value="Científico">Científico</option>
                      <option value="Manual">Manual</option>
                    </Select>
                  </>
                )}

                <Select
                  onChange={handleManualSelect}
                  placeholder="Seleção Manual ?"
                  marginLeft="1%"
                >
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </Select>

                {type === "edicao-normas" && (
                  <>
                    <Input
                      display="inline-block"
                      marginLeft={type === "edicao-normas" ? "1%" : "0"}
                      placeholder="Nome da Revista"
                      onChange={handleInputMagazineName}
                    ></Input>
                    <Input
                      onChange={handleInputTotalReference}
                      marginLeft="1%"
                      isRequired
                      placeholder="Numero de referências"
                    />
                  </>
                )}
              </FormControl>

              <FormControl marginTop="1%" display="inline-flex">
                <Select
                  placeholder="FastDelivery"
                  onChange={handleInputFastDelivery}
                >
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </Select>

                {fastDelivery === "1" ? (
                  <Input
                    onChange={handleInputFastDeliveryDays}
                    marginLeft="1%"
                    placeholder="Receber em quantos dias"
                  />
                ) : null}
                <Input
                  onChange={handleInputFigures}
                  marginLeft="1%"
                  placeholder="Quantidade de figuras"
                />

                {type === "geral" && (
                  <Input
                    onChange={handleInputWordsOriginal}
                    marginLeft="1%"
                    placeholder="Quant. Palavras"
                  />
                )}
              </FormControl>

              {type === "geral" && (
                <>
                  <Stack marginTop="50px">
                    <Text fontSize="lg">Serviço Adicional</Text>
                  </Stack>
                  <FormControl marginTop="2%">
                    <FormLabel>Edição das normas da Revista</FormLabel>
                    <Select
                      display="inline-block"
                      width={"40%"}
                      value={magazineEdit}
                      placeholder="Edição das normas da Revista"
                      onChange={handleInputMagazineEdit}
                    >
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </Select>
                    {magazineEdit == 1 ? (
                      <>
                        <FormControl marginTop="1%" display="inline-flex">
                          <Input
                            display="inline-block"
                            placeholder="Nome da Revista"
                            onChange={handleInputMagazineName}
                          ></Input>
                          <Input
                            onChange={handleInputTotalReference}
                            marginLeft="1%"
                            placeholder="Numero de referências"
                          />
                          {fastDelivery == 1 ? (
                            <Input
                              onChange={handleInputFastDeliveryDaysMagazine}
                              marginLeft="1%"
                              placeholder="Receber em quantos dias a edição das normas da revista"
                            />
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </>
              )}

              <Stack marginTop="50px">
                <Text fontSize="lg">Serviço</Text>
              </Stack>
              <FormControl width={"35%"} marginTop="15px">
                {type === "geral" && (
                  <Select
                    required
                    value={translated}
                    placeholder="O serviço está traduzido ?"
                    onChange={handleTranslated}
                  >
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </Select>
                )}
              </FormControl>
              <FormControl display="inline-flex" marginTop="15px">
                <Input onChange={handleInputTitle} placeholder="Titulo" />

                {type === "geral" && (
                  <>
                    <Select
                      onChange={handleInputInitialLanguage}
                      placeholder="Idioma de Origem"
                      marginLeft="1%"
                    >
                      <option value="PT-BR">Português(BR)</option>
                      <option value="EN">Inglês</option>
                    </Select>

                    <Select
                      onChange={handleInputFinalLanguage}
                      placeholder="Idioma Final"
                      marginLeft="1%"
                    >
                      <option value="PT-BR">Português(BR)</option>
                      <option value="EN">Inglês</option>
                    </Select>
                  </>
                )}
              </FormControl>
              <Stack margin="15px 0">
                <Text fontSize="md">Abstract</Text>
              </Stack>
              <FormControl>
                <JoditEditor
                  onBlur={handleBlurAreaChange}
                  value={textAreaValue}
                />
              </FormControl>

              <FormControl marginTop="2%" display="inline-flex">
                <Input
                  onChange={handleInputKeywords}
                  marginLeft="2%"
                  placeholder="Digite as palavras chave"
                />
              </FormControl>
            </Box>

            <Box>
              <Stack marginTop="50px" marginBottom="15px">
                <Text fontSize="lg">Arquivo</Text>
              </Stack>
              <Box marginTop={"25px"}>
                <Input
                  pt={2}
                  fontSize={"12px"}
                  type="file"
                  accept=".pdf, .doc, .docx"
                  multiple
                  onChange={handleFileChange}
                />
              </Box>
            </Box>

            <Stack marginTop="50px">
              <Text fontSize="lg">Expert</Text>
            </Stack>

            <Stack margin="15px 0">
              <Text fontSize="md">Descrição (para os experts)</Text>
            </Stack>
            <FormControl>
              <JoditEditor
                onBlur={handleInputDescription}
                value={description}
              />
            </FormControl>

            <Box marginTop="2%">
              <Button margin="2%">
                <Link to="/servicos">Voltar</Link>
              </Button>

              <Button colorScheme="blue" onClick={createOrder}>
                Salvar
              </Button>
            </Box>
          </Box>
        </Flex>
      </Container>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody textAlign="center">
            <Spinner size="xl" color="blue.500" />
            <Text mt="4">Estamos convidando os experts. Aguarde...</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
